


















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/OrderController'
import merchantController from '@/app/ui/controllers/MerchantController'
import OrderTextInput from './components/OrderTextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import OrderDropdown from './components/OrderDropdown/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import OrderItem from './components/OrderItem/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { ORDER_PAGINATION, Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import { Merchant } from '@/domain/entities/Merchant'
import { Dictionary } from 'vue-router/types/router'

interface Parameter {
  page?: number
  perPage?: number
  dateFrom?: string
  dateTo?: string
  buyerName?: string
  customerId?: number
  shipmentId?: string
  merchantId?: number
  merchantName?: string
  status?: string
}

interface DropdownInterface {
  value: string | number | null
  label: string | null
}

@Component({
  components: {
    OrderTextInput,
    DateTimePicker,
    OrderDropdown,
    Button,
    OrderItem,
    PaginationNav,
    Loading,
    EmptyState,
  },
})
export default class OrderListPage extends Vue {
  controller = controller
  merchantController = merchantController
  parameters: Parameter = {
    page: 1,
    perPage: ORDER_PAGINATION,
  }

  merchantList: DropdownInterface[] = []

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || ORDER_PAGINATION,
        dateFrom: queries.dateFrom || undefined,
        dateTo: queries.dateTo || undefined,
        buyerName: queries.buyerName || undefined,
        customerId: queries.customerId || undefined,
        shipmentId: queries.shipmentId || undefined,
        merchantId: queries.merchantId || undefined,
        merchantName: queries.merchantName || undefined,
        status: queries.status || undefined,
      }
    }

    this.fetchOrderList()
    this.fetchMerchantList((<string>this.$route.query.merchantName))
  }

  get params(): Record<string, string | number> {
    return {
      ...this.parameters,
    }
  }

  get statusList(): DropdownInterface[] {
    return controller.statusList.map(status => {
      return {
        value: status,
        label: status,
      }
    })
  }

  get merchant(): DropdownInterface | null {
    if (!this.parameters.merchantId) {
      return null
    }

    return {
      label: <string>this.parameters.merchantName,
      value: this.parameters.merchantId,
    }
  }

  set merchant(val: DropdownInterface | null) {
    this.parameters.merchantId = (<number>val?.value) || undefined
    this.parameters.merchantName = (<string>val?.label) || undefined
  }

  private fetchMerchantList(search?: string): void {
    if (search === '') search = undefined
    merchantController.getMerchantList({
      perPage: 30,
      merchantName: search,
    })
  }

  private merchantSearch = Utils.debounce((search: string): void => {
    this.fetchMerchantList(search)
  }, 500)

  @Watch('merchantController.merchantData')
  onBrandDataChanged(val: Merchant[]): void {
    this.merchantList = val.map(merchant => {
      return {
        value: <number>merchant.id,
        label: <string>merchant.name,
      }
    })
  }

  get status(): DropdownInterface | null {
    if (!this.parameters.status) {
      return null
    }

    return {
      label: this.parameters.status,
      value: this.parameters.status,
    }
  }

  set status(val: DropdownInterface | null) {
    this.parameters.status = (<string>val?.value) || undefined
  }

  private formatParams(parameters: Parameter): Record<string, string | undefined | number> {
    return {
      ...parameters,
      dateFrom: parameters.dateFrom
        ? dayjs(parameters.dateFrom)
            .startOf('day')
            .toISOString()
        : undefined,
      dateTo: parameters.dateTo
        ? dayjs(parameters.dateTo)
            .endOf('day')
            .toISOString()
        : undefined,
      customerId:
        parameters.customerId === 0 ? undefined : parameters.customerId,
    }
  }

  private fetchOrderList(): void {
    this.controller.getOrderList(this.formatParams(this.parameters))
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  private handleSearch(): void {
    this.fetchOrderList()
  }

  private handleExport(): void {
    controller.exportOrder(this.formatParams(this.parameters))
  }
}
