









































import { Component, Vue, Prop } from 'vue-property-decorator'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  inheritAttrs: false,
  components: {
    DropdownSelect,
    IconWarningTriangle,
  },
})
export default class OrderDropdown extends Vue {
  @Prop({ type: String }) private title!: string
  @Prop({ type: String }) private errorMessage!: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ type: Object }) private vuelidateObject!: Record<string, any>
  @Prop({ type: String, default: '' }) private placeholder!: string
  @Prop({ type: Boolean, default: false }) private hideCarret!: boolean

  private handleInput(e: { value: string; label: string }) {
    this.$emit('input', e)

    if (this.vuelidateObject) this.vuelidateObject.$touch()
  }

  get hasError(): boolean {
    return (
      this.vuelidateObject &&
      this.vuelidateObject.$invalid &&
      this.vuelidateObject.$dirty
    )
  }
}
