
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import { Order } from '@/domain/entities/Order'
import dayjs from 'dayjs'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Button,
  },
})
export default class OrderItem extends Vue {
  @Prop({ type: Object, required: true }) private data!: Order

  get date(): string {
    return dayjs(this.data.orderDate).format('DD MMM YYYY HH:mm')
  }

  get subtotal(): string {
    return `Rp. ${Utils.currencyDigit(Number(this.data.totalAmount))}`
  }
}
